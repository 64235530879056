import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import Seo from '@core/Seo';
import Highlight from '@core/Highlight';

import mq from '@mq';

const FourOhFourPage = () => (
  <>
    <GlobalStyles />
    <Seo pageTitle="Not found" />
    <Main>
      <Section>
        <Text>
          <h3>
            <Highlight>404. That&apos;s an error.</Highlight>
          </h3>
          <div>The requested URL was not found on this server.</div>
          <div>That&apos;s all we know.</div>
        </Text>
      </Section>
    </Main>
  </>
);

const Main = styled.main`
  flex: 1;
`;

const Section = styled.section`
  &:first-child {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }
`;

const Text = styled.div`
  grid-column: 1 / -1;
  font: var(--font-xs);

  h3 {
    z-index: 1;
    position: relative;
    --color-primary: var(--color-secondary);
  }

  ${mq.small} {
    font: var(--font-s);
  }

  ${mq.medium} {
    grid-column: 2 /-2;
  }

  ${mq.large} {
    grid-column: 3 /-3;
  }

  ${mq.huge} {
    grid-column: 6 /-6;
  }
`;

const GlobalStyles = createGlobalStyle`
  body, html, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
  }
`;

export default FourOhFourPage;
